import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, toast } from '@core';
import { SectionContainerSC as SectionContainer, Text } from '@components';

import { sendSuggestedLocation } from '@api/servers';

import { ServerInput } from './components';

const RequestServerSection = ({ title, description }) => {
  const { t } = useTranslation();
  const [suggestedServer, setSuggestedServer] = useState();
  const [disabledButton, setDisabledButton] = useState(true);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const clickSubmitHandler = async ({ value }) => {
    if (value && executeRecaptcha) {
      setDisabledButton(true);

      const recaptchaToken = await executeRecaptcha('server_request');

      sendSuggestedLocation({ value, token: recaptchaToken }).then(() =>
        toast.success(
          <Box minWidth={{ sm: 522 }}>
            <Text.Body4Strong as="span" color="white" textAlign="center">
              {t('servers:requestServer.yourServerRequestSubmitted')}
            </Text.Body4Strong>
          </Box>
        )
      );
    }
  };

  return (
    <SectionContainer title={title} description={description}>
      <Box mb={{ _: 16, lg: 32 }} display="flex" justifyContent="center" alignItems="flex-end">
        <Grid.Row width={{ _: 204, sm: 240 }} flexDirection="column">
          <Text.Body4 lineHeight="15px" mb={8}>
            {t('servers:requestServer.enterLocationName')}
          </Text.Body4>
          <ServerInput
            value={suggestedServer}
            type="text"
            name="serverInput"
            onChange={(event) => {
              setSuggestedServer(event.target.value);
              setDisabledButton(!event.target.value);
            }}
            placeholder={t('servers:requestServer.countryOrCity')}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                setSuggestedServer(event.target.value);
                clickSubmitHandler({ value: event.target.value });
              }
            }}
          />
        </Grid.Row>

        <Box ml={8} width={{ _: 100, sm: 120 }}>
          <Button
            fullWidth
            type="button"
            disabled={disabledButton}
            onClick={() => clickSubmitHandler({ value: suggestedServer })}
          >
            {t('common:actions.submit')}
          </Button>
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default RequestServerSection;
