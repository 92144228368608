import axios from './axios';

const SERVER = 'https://server.' + process.env.API_DOMAIN;
export const SERVERS_URL = SERVER + '/v2/servers';
const SUGGEST_LOCATION_URL = SERVER + '/v1/suggest_location';

export const getServers = () => axios.get(SERVERS_URL).then((response) => response.data);

export const sendSuggestedLocation = ({ value, token }) =>
  axios.post(SUGGEST_LOCATION_URL, { value, token });
