import styled from 'styled-components';

const StyledInput = styled.input`
  border: ${({ error, theme }) =>
    `solid 1px ${error ? theme.colors.danger_80 : theme.colors.darkBlue_40}`};
  padding: 10px 16px;
  border-radius: 4px;
  height: 42px;
  font-size: 14px;
  width: 100%;
  font-family: ${({ theme }) => theme?.fonts?.default};

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkBlue_60};
  }
  &:focus {
    outline: unset;
  }
  &:hover {
    border: ${({ error, theme }) =>
      `solid 1px ${error ? theme.colors.danger_80 : theme.colors.darkBlue_60}`};
  }

  &.error {
    border: ${({ error, theme }) =>
      `solid 1px ${error ? theme.colors.danger_80 : theme.colors.darkBlue_60}`};
  }
`;

export default StyledInput;
